import React from 'react';
import {
  Link
} from "react-router-dom";

export default function DateItem(props) {
  function handleClick() {}
  var h1 = 'd';

  const formatDay = (day) => {
    var date = new Date(day);
    return date.toLocaleString(window.navigator.language, {weekday:'short'})
  }

  const formatDate = (day) => {
    // Gets today's date
    var date = new Date(day);
    var dd = date.getDate(day) < 10 ? '0' + date.getDate(day) : date.getDate(day);
    var mm = date.toLocaleString(window.navigator.language, {month: 'short' });
    return mm + '\n' + dd;
  }
  
  return (
    <>
        <Link className='currencies__item__link' 
          to={{
            pathname: '/blog', 
            hash: props.label.toString(),
            search: props.label.toString()
          }}>
            <p className='currrencies__item__label'>{formatDay(props.text)}</p>
            <p className='currencies__item__text'>{formatDate(props.text)}</p>
        </Link>
    </>
  );
}
