import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useLocation } from "react-router-dom";
import Copyrights from '../components/Copyrights';
import Currencies from '../components/Currencies';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  scales: {
    y: { stacked: true },
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Chart.js Line Chart',
    },
  },
};

  
export default function Currency() {
  let currency = useLocation().search.replace("?","");
  let values = [];
  let labels = [];
 
  const getData=()=>{
    let source ='';
    try {
      source = require('../../assets/data/currencies/' + currency.toLowerCase() + '.json');
      source.DataSet.Series.Obs.map((elem) => {
        labels.push(elem['@TIME_PERIOD']);
        values.push(elem['@OBS_VALUE']);
      })
    } catch(e) {
      if (e instanceof TypeError) {
        // declarações para manipular exceções TypeError
      } else if (e instanceof RangeError) {
          // declarações para manipular exceções RangeError
      } else if (e instanceof EvalError) {
          // declarações para manipular exceções EvalError
      } else {
        // declarações para manipular quaisquer exceções não especificadas
        console.log(e); // passa o objeto de exceção para o manipulador de erro
      }
    }
    return [labels,values]

  }

  [labels, values] = getData();
  const data = {
    labels,
    datasets: [
      {
        label: currency,
        data: values,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  }

  useEffect(() => {
    getData();
  },[]);


  return (  
    <>
      <Currencies/>
      <Line options={options} data={data} />;
      <Copyrights/>
    </>
  );

}
