import React from 'react';
import '../App.css';
import { useLocation } from "react-router-dom";

import { PayPalButton } from "react-paypal-button-v2";

export default function Services() {
  let label = useLocation().hash;
  let price = useLocation().price;
  console.log("label  ",label);

  return <>
    <h1 className='services'>{label}</h1>
    <div className="purchase">
      <div><h1>$180{price} per hour</h1></div>
      <PayPalButton
        amount=""
        shippingPreference='NO_SHIPPING'
        onSuccess={(details, data) => {
          alert("Transaction completed by " + details.payer.name.given_name);

          // OPTIONAL: Call your server to save the transaction
          return fetch("/paypal-transaction-complete", {
            method: "post",
            body: JSON.stringify({
              orderId: data.orderID
            })
          });
        }}
        options={{
          clientId: "AX3LzoRDapxKUw_2n24iDF4j9feqrc6nfbTzR4YKW4qj5DezQOPGoWx5J609FI8AnNu8U9lpJ20SSn5L"
        }}
      />
     			
    </div>				 
  </>;
}
