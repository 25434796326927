import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          Bio: {
            text: 'Bio',
          },
          Copyrights: {
            text: "IURIX ©2024. All rights reserved."
          },
          Contact: {
            text: "Contact us"
          },
          Creating_software_for_a_purpose: {
            text: "Creating software for a purpose: to help people succeed in their business.",
          },
          Elections: {
            text: "ELEIÇÔES"
          },
          Finance: {
            text: "Finance"
          },
          Game: {
            text: 'Game',
          },
          GET_STARTED: {
            text: "GET STARTED",
          },
          IT_CATALIZES_YOUR_BUSINESS: {
            text: "I.T. CATALIZES YOUR BUSINESS!",
          },
          Jobs: {
            text: "Jobs"
          },
          Portfolio: {
            text: "Portfolio"
          },
          Services: {
            text: "Services"
          },
          SIGN_UP: {
            text: "SIGN UP"
          },
          SIGN_IN: {
            text: "SIGN IN"
          },
          Terms_Conditions: {
            text: "Terms & Conditions",
          },
          Privacy_Policy: {
            text: "Privacy Policy",
          },
          Welcome: {
            text: 'Welcome to IURIX!',
          },
        },
      },
      pt: {
        translation: {
          Welcome: {
            text: "Bem-vindo à IURIX!",
          },
          IT_CATALIZES_YOUR_BUSINESS: {
            text: "T.I. ACELERA O SEU NEGÓCIO!",
          },
          Creating_software_for_a_purpose: {
            text: "Criamos software com um propósito: ajudar o cliente a alcançar o sucesso.",
          },
          Elections: {
            text: "Eleições 2022"
          },
          Finance: {
            text: "Finanças"
          },
          GET_STARTED: {
            text: "VEJA MAIS",
          },
          Jobs: {
            text: "Vagas"
          },
          Portfolio: {
            text: "Portfólio"
          },
          Services: {
            text: "Serviços"
          },
          Contact: {
            text: "Contato"
          },
          SIGN_UP: {
            text: "ENTRAR"
          },
          Terms_Conditions: {
            text: "Termos & Condições",
          },
          Privacy_Policy: {
            text: "Política de Privacidade",
          },
          Copyrights: {
            text: "IURIX ©2022. Todos os direitos reservados."
          },
          Currencies: {
            text: "Câmbio"
          },
        },
      },
      fr: {
        translation: {
          Welcome: {
            text: "Bienvenue sur IURIX!",
          },
          IT_CATALIZES_YOUR_BUSINESS: {
            text: "L'INFORMATIQUE ACCÉLÈRE VOTRE ENTREPRISE!",
          },
          Elections: {
            text: "Eleições 2022"
          },
          Portfolio: {
            text: "Portfefeuille"
          },
          Services: {
            text: "Les Services"
          },
          Contact: {
            text: "Nous Contacter"
          },
          SIGN_UP: {
            text: "S'inscrire"
          },
        },
      },
    },
    lng: 'en', // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });