import React from 'react';
import './Game.css';

import Copyrights from '../components/Copyrights';
function Home() {
  return (
    <>
      <div className="container">
        <h1>Bemvindo ao Money!</h1>
        <p>Clique no botão abaixo para configurar o jogo.</p>
        <a href="/settings">Settings</a>
      </div>
      <Copyrights/>
    </>
  );
}

export default Home;
