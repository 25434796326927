import React from "react";
import { Link } from "react-router-dom";

const ButtonMailto = ({ mailto, label }) => {
    return (
        <Link
            className="item_link"
            to='#'
            onClick={(e) => {
                window.location.href = mailto;
                e.preventDefault();
            }}
        >
            <i className="fas fa-envelope fa-3x mb-3 sr-contact-2"></i>
            {label}
        </Link>
    );
};

export default ButtonMailto;