import React from 'react';

import './Donate.css'
export default function Donate() {

    return (
        <>
			<div className='container'>
				<h1>We have a purpose to help others!</h1>
				<p> Scan the QR Code or click to Donate</p>
                <a className="donate-link" href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&amp;hosted_button_id=THTHY7L9FU5EQ&amp;source=url" target="_blank">
                    <figure data-category={'QRCode'}>
						<img
						alt='QR code to donate'
						src={require('../assets/images/qrcode-donate.png')}/>
					</figure>
                </a>


{/*	    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
<input type="hidden" name="cmd" value="_s-xclick" />
<input type="hidden" name="hosted_button_id" value="THTHY7L9FU5EQ" />
<input type="image" src="https://www.paypalobjects.com/pt_BR/BR/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Faça doações com o botão do PayPal" />
<img alt="" border="0" src="https://www.paypal.com/pt_BR/i/scr/pixel.gif" width="1" height="1" />
</form>
*/}
				<form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
					<input type="hidden" name="cmd" value="_s-xclick" />
					<input type="hidden" name="hosted_button_id" value="THTHY7L9FU5EQ" />
					<input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
					
				</form>
				

				<h2>SHARE this link!</h2>
                <a className="donate-link" href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&amp;hosted_button_id=THTHY7L9FU5EQ&amp;source=url" target="_blank">
					<p> https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&amp;hosted_button_id=THTHY7L9FU5EQ&amp;source=url </p>
				</a>
				

       
			</div>
        </>
    );
}


