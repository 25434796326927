import React from 'react';
import './Currencies.css';

import CurrencyItem from './CurrencyItem';

/*** # TODO turn file input as a scheduled daily dynamic input ***/
import data from '../assets/data/currencies/eurofxref.json';

import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

import '../i18n';
import { useTranslation } from 'react-i18next';


export default function Currencies() {
    const { t } = useTranslation();

    /* const myList = data.Cube.Cube.Cube.map(
        (item) => <p>{item['@currency']} {item['@rate']}</p>
    ); */
    
    const slideLeft = () => {
        var slider = document.getElementById('slider');
        slider.scrollLeft = slider.scrollLeft - 500;
    };
    
    const slideRight = () => {
        var slider = document.getElementById('slider');
        slider.scrollLeft = slider.scrollLeft + 500;
    };

    return (
        <> 
            <h1>{t('Currencies.text')}</h1>
            <div className='relative flex items-center'>                       
                <div className='currencies__item__block'>
                    <p className='currencies__item__label'>EUR $1</p>
                </div>

                <MdChevronLeft className='opacity-50 cursor-pointer hover:opacity-100' onClick={slideLeft} size={33} />
                <div id='slider' className='w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide'>
                    {data.Cube.Cube.Cube.map((item) => (
                        <div key={item['@currency']} className='w-[220px] inline-block p-2 cursor-pointer hover:scale-105 ease-in-out duration-300' style={{width:120}}>
                            <CurrencyItem
                                text={item['@rate']}
                                label= {item['@currency']}
                                path= {'/currency'}
                            />   
                        </div>
                    ))}
                </div>
                <MdChevronRight className='opacity-50 cursor-pointer hover:opacity-100' onClick={slideRight} size={40} />                
            </div>                   
        </>
    );
}
