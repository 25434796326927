import React from 'react';
import '../App.css';

import Currencies from '../components/Currencies';
import Cards from '../components/Cards';
import Contactus from '../components/Contactus';
import Donate from '../components/Donate';
import Copyrights from '../components/Copyrights';
import Navbar from '../components/Navbar';

function Home() {
  return (
    <>
      <Navbar/>
      <Currencies/>
      <Cards/>
      <Contactus/>
      <Donate/>
      <Copyrights/>
    </>
  );
}

export default Home;
