import React from 'react';
import ButtonMailto from './ButtonMailto';
import { Link } from "react-router-dom";
import './Contactus.css';


function Contactus() {
  return (
    <section id='contact'>    
      <div className='container'>
        <h1 id="contact">Let's Get In Touch!</h1>
        <p>Ready to start your next project with us? <br />That's great! Give us a call or send us an email and we will get back to you as soon as possible!</p>
        
        <div className='wrapper'>
          <ul className='items'>
          <li className='item'>
            <a href='https://wa.me/5511998896571?text=Hello%20there!%20We%20would%20like%20to%20contact%20IURIX!'>
                  <p>Whatsapp: </p>
            <p>+5511998896571</p>
            </a>
            </li>
            <li className='item'>
                <Link className="item_link" to='/+5511998896571'>
                    <i className="fas fa-phone fa-3x mb-3 sr-contact-1"></i>
                    <p>+55 (11) 4950-8367</p>
                </Link>
            </li>
            <li className='item'>
                <ButtonMailto label="contact@iurix.com" mailto="mailto:contact@iurix.com" />
            </li>

          </ul>
        </div>
      </div>
    </section>
  );
}

export default Contactus;
