import React from 'react';
import './Copyrights.css';
import { Link } from 'react-router-dom';

import '../i18n';
import { useTranslation } from 'react-i18next';

function Copyrights() {
  const { t } = useTranslation();

  return (
    <div className='footer-container'>
      <div className='copyright-wrapper'>
          <ul className='items'>
            <li className='item_footer'>
              <Link to='/terms-conditions' className='copyright-link'>{t('Terms_Conditions.text')}</Link>
            </li>
            <li className='item_footer'>
              <Link to='/privacy-policy' className='copyright-link'>{t('Privacy_Policy.text')}</Link>
            </li>
          </ul>
          <div className='copyright-text'><p>{t('Copyrights.text')}</p></div>           
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>

          <div className='social-icons'>
            <a
              className='social-icon-link facebook'
              href='https://www.facebook.com/iurixtech'
              target='_blank'
              aria-label='Facebook'
            >
              <i className='fab fa-facebook-f' />
            </a>
            <a
              className='social-icon-link instagram'
              href='https://www.instagram.com/iurixtech/'
              target='_blank'
              aria-label='Instagram'
            >
              <i className='fab fa-instagram' />
            </a>
            <a
              className='social-icon-link youtube'
              href='https://www.youtube.com/user/TheIurix'
              target='_blank'
              aria-label='Youtube'
            >
              <i className='fab fa-youtube' />
            </a>
            <a
              className='social-icon-link twitter'
              href='https://twitter.com/iurixcom'
              target='_blank'
              aria-label='Twitter'
            >
              <i className='fab fa-twitter' />
            </a>
            <a
              className='social-icon-link twitter'
              href='https://www.linkedin.com/in/iuridearaujo/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i className='fab fa-linkedin' />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Copyrights;
