import React from 'react';
import '../App.css';
import Navbar from '../components/Navbar';
import HeroSection from '../components/HeroSection';
import Copyrights from '../components/Copyrights';
export default function Intro() {
  return (
    <>
      <Navbar />
      <HeroSection />
      <Copyrights />
    </>
  );
}

