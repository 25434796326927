import React from 'react';
import { useLocation } from "react-router-dom";
import '../App.css';
import Dates from '../components/Dates';
import Posts from '../components/Posts';
import Copyrights from '../components/Copyrights';


export default function Blog() {
  let timestamp = useLocation().search.replace("?","");
  return (
    <>
      <Dates/>
      <Posts date={timestamp}/> 
      <Copyrights/>
    </>
  );
}
