// App.js

import React, { useState } from 'react';
import '../App.css';
import LoginForm from './LoginForm';
import Navbar from '../components/Navbar';
function Login() {
  const [isLoggedIn, setLoggedIn] = useState(false);

  const handleLogin = () => {
    // Perform authentication here, e.g., make an API call
    // For simplicity, just hardcoding username and password
    const username = 'user';
    const password = 'password';

    // Replace with actual authentication logic
    if (username === 'user' && password === 'password') {
      setLoggedIn(true);
    } else {
      alert('Invalid credentials');
    }
  };

  const handleLogout = () => {
    setLoggedIn(false);
  };

  return (
    <>
      <Navbar/>
      <div className='container'>
        {isLoggedIn ? (
          <div>
            <h1>Welcome!</h1>
            <button onClick={handleLogout}>Logout</button>
          </div>
        ) : (
          <LoginForm onLogin={handleLogin} />
        )}
      </div>
    </>
  );
}

export default Login;
