import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Bio from '../pages/Bio';
import Blog from '../pages/Blog';
import Currency from '../pages/Currency';
import Eleicoes2022 from '../pages/Eleicoes2022';
import Finances from '../pages/Finances';
import Game from '../pages/Game';
import Home from '../pages/Home';
import Intro from '../pages/Intro';
import Login from '../pages/Login';
import Products from '../pages/Products';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermsConditions from '../pages/TermsConditions';

function DesktopRoutes() {
    return (
      <>
        <Router>
          <Routes>
            <Route path='/' element={<Intro/>} />
            <Route path='/bio' element={<Bio/>} />
            <Route path='/blog' element={<Blog/>} />
            <Route path='/eleicoes2022' element={<Eleicoes2022/>} />
            <Route path='/home' element={<Home/>} />
            <Route path='/game' element={<Game/>} />
            <Route path='/finances' element={<Finances/>} />
            <Route path='/products' element={<Products/>} />
            <Route path='/currency' element={<Currency/>} />
            <Route path='/login' element={<Login/>} />
            <Route path='/terms-conditions' element={<TermsConditions/>} />
            <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
  
          </Routes>
        </Router>
      </>
    );
  }
  
  export default DesktopRoutes;
  