import React from 'react';
import Navbar from '../components/Navbar';
import Home from '../pages/Home';
import Intro from '../pages/Intro';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Services from '../pages/Services';
import Products from '../pages/Products';
import SignUp from '../pages/SignUp';
import TermsConditions from '../pages/TermsConditions';
import PrivacyPolicy from '../pages/PrivacyPolicy';



function MobileRoutes() {
    return (
      <>
        <Router>
          <Navbar />
          <Routes>
            <Route path='/' element={<Intro/>} />
            <Route path='/home' element={<Home/>} />
            <Route path='/services' element={<Services/>} />
            <Route path='/products' element={<Products/>} />
            <Route path='/sign-up' element={<SignUp/>} />
            <Route path='/terms-conditions' element={<TermsConditions/>} />
            <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
  
          </Routes>
        </Router>
      </>
    );
  }
  
  export default MobileRoutes;
  