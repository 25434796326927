import React from 'react';
import './Currencies.css';

import DateItem from './DateItem';

import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

import '../i18n';
import { useTranslation } from 'react-i18next';


export default function Dates() {
    const { t } = useTranslation();
    
    const slideLeft = () => {
        var slider = document.getElementById('slider');
        slider.scrollLeft = slider.scrollLeft - 500;
    };
    
    const slideRight = () => {
        var slider = document.getElementById('slider');
        slider.scrollLeft = slider.scrollLeft + 500;
    };
    // Gets today's date
    var today = new Date();
    var yesterday = (today - 8.64e7);
    // Creates array of past dates
    const dates_arr = Array(12).fill(yesterday).map((yesterday,i) => yesterday - 8.64e7 * i).map(day=>day);
    var today_pretty = today.toDateString();
    //console.log(dates_arr)
    return (
        <> 
            <h1>Archive</h1>
            <div className='relative flex items-center'>                                   
                <div className='currencies__item__block'>
                    <p className='currencies__item__label'>{'Today:\n'+today_pretty}</p>
                </div>
                <MdChevronLeft className='opacity-50 cursor-pointer hover:opacity-100' onClick={slideLeft} size={33} />
                <div id='slider' className='w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide'>
                    {dates_arr.map((item,i) => (                        
                        <div key={item} className='w-[220px] inline-block p-2 cursor-pointer hover:scale-105 ease-in-out duration-300' style={{width:120, padding:5, textAlign:'center'}}>    
                            <DateItem
                                text={item}
                                label= {item}
                                path= {'/blog'}
                            />   
                        </div>
                    ))}
                </div>
                <MdChevronRight className='opacity-50 cursor-pointer hover:opacity-100' onClick={slideRight} size={40} />                
            </div>                   
        </>
    );
}
