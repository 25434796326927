import React from 'react';
import '../App.css';
import Copyrights from '../components/Copyrights';
import Navbar from '../components/Navbar';

export default function Map() {
  return (
    <>
      <Navbar />
      <div>
        <iframe width="100%" height="700" src="https://lookerstudio.google.com/embed/reporting/733319d6-aa34-44ed-b008-741bbbf686ab/page/eFarD" ></iframe>
      </div>
      <Copyrights/>
    </>
  );
}
