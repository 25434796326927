import React, { useEffect, useState } from 'react';
import '../App.css';
import "../components/Posts.css";

import Dates from '../components/Dates';
import Copyrights from '../components/Copyrights';

import Post from "../components/Post";

export default function Blog() {
  let [data,setData] = useState(null);

  function getData() {
    try {
      // Gets today's date
      var date = new Date();
      var dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      var mm = date.getMonth() < 10 ? '0' + (date.getMonth()+1) : (date.getMonth()+1);
      var yyyy = date.getFullYear();
      // Gets today's posts
      // Gets today's or latest posts      
      while (data == null) {
        //data = require('../../assets/data/finance/fin-'+yyyy+'-'+mm+'-'+dd+'.json');
        dd = dd - 1;
        //date = date - 1
        dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        mm = date.getMonth() < 10 ? '0' + (date.getMonth()+1) : (date.getMonth()+1);
        yyyy = date.getFullYear();
    
      }
      setData(data);
      return
    } catch(e) {
      console.log(e);
    }
  }
  

  useEffect(() => {
    getData();
  }, []);

  console.log(data)
  return (
    <>
      <Dates/>
      <div className="posts-container">
        { data?
            data.map((post, index) => (
              <Post key={index} index={index} post={post} />
            ))
            :
              <h1>No content</h1>
        }
      </div>
      <Copyrights/>
    </>
  );
}
