import React from 'react';
import { Link } from 'react-router-dom'

import '../App.css';
import './HeroSection.css';

import '../i18n';
import { useTranslation } from 'react-i18next';

export default function HeroSection() {
  const { t } = useTranslation();

  return (
    <div className='hero-container'>
      <video src={require('../assets/videos/video-2.mp4')} autoPlay loop muted />
      <h1>{t('IT_CATALIZES_YOUR_BUSINESS.text')}</h1>
      <p align='center'>{t('Creating_software_for_a_purpose.text')}</p>
      <div className='hero-btns'>
      <Link
                to='/Home'
                className='nav-links'
                
              >
                {t('GET_STARTED.text')}
              </Link>

      </div>
    </div>
  );
}