import React from 'react';
import DesktopRoutes from './routes/DesktopRoutes';
import MobileRoutes from './routes/MobileRoutes';
import {isMobile,isBrowser,isSmartTV } from "react-device-detect"

import './App.css';

export const App = () => {
    return (
        <>
            {isMobile && <MobileRoutes />}
            {(isBrowser || isSmartTV) && <DesktopRoutes />}
        </>
    )
}

export default App;