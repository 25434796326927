import React from 'react';
import {
  Link
} from "react-router-dom";

function CurrencyItem(props) {

  function handleClick() {}
  return (
    <>
      <Link className='currencies__item__link' 
        to={{
          pathname: '/currency', 
          hash: props.label.toString(),
          search: props.label
        }}>
        <p className='currrencies__item__label'>{props.label}:</p>
        <p className='currencies__item__text'>${props.text}</p>
      </Link>   
    </>
  );
}
export default CurrencyItem;
