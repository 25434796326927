
import React from "react";
import ReactHtmlParser, {processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import "./Post.css";
const Post = ({ post: { title, body, imgUrl, author }, index }) => {
  return (  
    <div className="post-container">
     
      {ReactHtmlParser(body)}
      <div className="info"> 
        {/*
         <h1 className="heading">{ReactHtmlParser(title)}</h1>
        <img className="image" src={imgUrl} alt="post" />
        <h4>Posted by: {author}</h4>
        */}
      </div>
    </div>
  );
};
  
export default Post;