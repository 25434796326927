import React from 'react';
import { useLocation } from "react-router-dom";
import '../App.css';
import Copyrights from '../components/Copyrights';


export default function Bio() {
  return (
    <>
      <div>
        Hello world!Hello world!Hello world!Hello world!Hello world!Hello world!Hello world!Hello world!Hello world!Hello world!<br/>
        Hello world!Hello world!Hello world!Hello world!Hello world!Hello world!Hello world!Hello world!Hello world!Hello world!<br/>
        Hello world!Hello world!Hello world!Hello world!Hello world!Hello world!Hello world!Hello world!Hello world!Hello world!<br/>
        Hello world!Hello world!Hello world!Hello world!Hello world!Hello world!Hello world!Hello world!Hello world!Hello world!<br/>
        Hello world!Hello world!Hello world!Hello world!Hello world!Hello world!Hello world!Hello world!Hello world!Hello world!<br/>

        Dissertations
        Artciles
        Workshops
        Videos
        Certificates
        Resume
      </div>
      <Copyrights/>
    </>
  );
}
